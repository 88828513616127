import React from "react";
import { Link } from "gatsby";

import happeningsBG from "../images/happeningsBGImage.png";
import nextEventIcon from "../images/nextEventIcon.png";
import Head from "../components/head";
import happeningsStyles from "../pages/events.module.css";
import EventStyles from "./event.module.css";

import { graphql } from "gatsby";

export const query = graphql`
  query($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      title
      date(formatString: "MMMM DD, YYYY")
      time
      slug
      locationAddress
      description {
        description
      }
    }
  }
`;

const eventPage = props => {
  return (
    <>
      <Head title={props.data.contentfulEvent.slug}></Head>
      <div className={happeningsStyles.happeningsHeroSection}>
        <img src={happeningsBG} />
        <div className={happeningsStyles.nextEventContainer}>
          <img src={nextEventIcon}></img>
          <div>
            <p className={happeningsStyles.nextEventTop}>Event</p>
            <p className={happeningsStyles.nextEventBottom}>
              {props.data.contentfulEvent.title}
            </p>
          </div>
        </div>
      </div>
      <section className={happeningsStyles.eventSection}>
        <div className={EventStyles.eventItem}>
          <h2>{props.data.contentfulEvent.title}</h2>
          <div className={EventStyles.dateTime}>
            <p className={EventStyles.label}>Date:</p>
            <p className={EventStyles.date}>
              {props.data.contentfulEvent.date}
            </p>
            <p className={EventStyles.time}>
              {props.data.contentfulEvent.time}
            </p>
          </div>
          <div className={EventStyles.location}>
            <p className={EventStyles.label}>Location:</p>
            <p className={EventStyles.date}>
              {props.data.contentfulEvent.locationAddress}
            </p>
          </div>
          <div className={EventStyles.description}>
            <p className={EventStyles.label}>Description:</p>
            <p className={EventStyles.descriptionCopy}>
              {props.data.contentfulEvent.description !== null
                ? props.data.contentfulEvent.description.description
                : "No Description"}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default eventPage;
